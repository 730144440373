/**
 * The scroll function allows you to add smooth scrolling.
 *
 * @param  Object breakpoints
 */
export default function (breakpoints) {
    $('.content__col a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);

            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 180
                }, 1000);
                return false;
            }
        }
    });
}
