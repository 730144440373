window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('./vendor/match-height');

require('./scripts/cookie');
require('./scripts/event');
require('./scripts/scroll');


import header from './scripts/header';
import merge from './scripts/merge';
import navigation from './scripts/navigation';
import setBackgroundImage from './scripts/setBackgroundImage';
import toggle from './scripts/toggle';
import googleReCaptcha from './scripts/googleRecaptcha';

import basket from './scripts/basket';

require('./vendor/basket');
import filters from './scripts/filters';

/**
 * Main Scripts
 *
 * This main scripts file is where you'll write most
 * of your (jQuery) scripts.
 */

(function ($) {

    /**
     * As soon as this file is loaded, jQuery will remove the `no-jquery`
     * class from the `body`. This indicates that jQuery has been loaded.
     */
    $('body').removeClass('no-jquery');

    /**
     * As soon as this file is loaded, jQuery will remove the `u-jquery`
     * class from the hidden `divs`. This indicates that jQuery has been loaded.
     */
    $('.u-jquery').removeClass('u-jquery');


    /**
     * Initialize Google reCaptcha
     */
    googleReCaptcha();

    /**
     * Object with breakpoint sizes defined. These are
     * based on Bootstrap's Grid.
     *
     * @type Object
     */
    var breakpoints = {
        "xs": 480,
        "sm": 768,
        "md": 992,
        "lg": 1200
    };

    // Header
    header(breakpoints);

    $(window).resize(function () {
        header(breakpoints);
    });

    // Navigation
    navigation(breakpoints);

    /**
     * Make sure touch-enabled devices can view the drop-down menus
     * starting from a landscape mode (> breakpoints.sm)
     */
    $(window).resize(function () {
        if ($(window).outerWidth() > breakpoints.sm) {
            $('.nav .nav__item:has(ul)').doubleTapToGo();
        }
    });

    // Merge
    merge(breakpoints);

    $(window).resize(function () {
        merge(breakpoints);
    });

    // Toggle
    toggle(breakpoints);

    $(window).resize(function () {
        toggle(breakpoints);
    });

    // Smooth scroll
    scroll();

    // Replace `img` with `background-image` in parent `div`
    setBackgroundImage();

    // Webshop
    basket();

    filters({
        afterLoad: function () {
            // MatchHeight e.d.
            $('.product__item .info').matchHeight({
                byRow: true
            });
            $('.product__item .info h2').matchHeight({
                byRow: true
            });
            $('.product__item .info p').matchHeight({
                byRow: true
            });
            $('.product__item .info .cost').matchHeight({
                byRow: true
            });
        }
    });


    // MatchHeight e.d.
    $('.product__item .info').matchHeight({
        byRow: true
    });
    $('.product__item .info h2').matchHeight({
        byRow: true
    });
    $('.product__item .info p').matchHeight({
        byRow: true
    });
    $('.product__item .info .cost').matchHeight({
        byRow: true
    });


    // Preview product
    $('.js-gallery .inner').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        arrows: false,
        draggable: false,
        fade: true,
        asNavFor: '.js-gallery-nav',
        adaptiveHeight: true
    });

    $('.js-gallery-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        swipeToSlide: true,
        focusOnSelect: true,
        centerMode: false,
        asNavFor: '.js-gallery .inner',
        prevArrow: $('.prevArrow'),
        nextArrow: $('.nextArrow'),
        responsive: [
            {
                breakpoint: 4000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }]
    });

    // MatchHeight
    $('.headlines .news .item h3').matchHeight({
        byRow: true
    });
    $('.headlines .calendar .item h3').matchHeight({
        byRow: true
    });
    $('.headlines .match').matchHeight({
        byRow: true
    });

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });

    // Slick
    $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        afterShow: function (instance, slide) {
            $('.js-gallery').slick('slickGoTo', slide.index);
            $('.js-gallery-nav').slick('slickGoTo', slide.index);
        },
        buttons: [
            'slideShow',
            'fullScreen',
            'thumbs',
            // 'share',
            //'download',
            //'zoom',
            'close'
        ]
    });

    // Slider Hero
    $('.js-hero-slider').slick({
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        rows: 0,
        dots: true,
        autoplaySpeed: 4000
    });

    // Categories slider
    $('.js-brandslider').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        pauseOnHover: false,
        swipeToSlide: true,
        focusOnSelect: true,
        arrows: false,
        rows: 0,
        dots: false,
        autoplay: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
            }
        },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });


    // Search toggle
    $(".header .search button").click(function (e) {
        if ($(window).outerWidth() > breakpoints.md) {
            e.preventDefault();
            $('.header .search input[type=search]').focus();
        }

        var form = $(this).parent('form');
        var status = $(this).attr('data-active');
        var textHasBeenInserted = $(this).siblings('.box').find('.keywords').val().length;

        if (status === 'false') {
            $('.header .search').toggleClass("active");
            $('.header .searchbar').toggleClass("active");

            $(this).attr('data-active', 'true');
        } else if (textHasBeenInserted === 0) {
            $('.header .search').toggleClass("active");
            $('.header .searchbar').toggleClass("active");

            $(this).attr('data-active', 'false');
        } else {
            form.submit();
        }
    });


    //Add spans slider en header text
    $('.hero__item h2, .hero__item h3').wrapInner("<span></span>");

    // Leftnav + Filters toggle
    $('.js-toggle-left-navigation').off('click').on('click', function () {
        var ToggleSubMenu = $('.left__nav');
        var ToggleMenuButton = $('.js-toggle-left-navigation');

        if (ToggleSubMenu.hasClass('active')) {
            ToggleSubMenu.removeClass('active');
            ToggleMenuButton.removeClass('reverse active');
        }
        else {
            ToggleSubMenu.addClass('active');
            ToggleMenuButton.addClass('reverse active');
        }
        return false;
    });

    $('.filtertogglebutton').off('click').on('click', function () {
        var ToggleFilter = $('.filter');
        var ToggleFilterButton = $('.filtertogglebutton');

        if (ToggleFilter.hasClass('active')) {
            ToggleFilter.removeClass('active');
            ToggleFilterButton.removeClass('reverse active');
        }
        else {
            ToggleFilter.addClass('active');
            ToggleFilterButton.addClass('reverse active');
        }
        return false;
    });

})(jQuery);
